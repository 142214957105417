/* Swiper Data */
.mySwiper{
  width: 100%;
}
.dashboard-container {
  box-shadow: 0px 0px 16px 0px #0000003d;
  padding: 20px 30px !important;
  border-radius: 10px;
  margin: 2rem 0 0 0;
}
.dashboard_main {
  padding: 20px 100px !important;
}
.input-group > input {
  outline: none !important;
}
.input-group > input :focus-visible {
  outline: none !important;
}
.swiper-wrapper {
  /* margin: auto !important; */
  /* transform: translate3d(0px, 0px, 0px) !important; */
}
/* Change the color of the active pagination bullet */

/* Add margin-top to the Swiper container */
.mySwiper {
  /* margin-top: 20px; This will add a 20px margin at the top of the Swiper container */
}
.data .swiper-slide {
  padding-bottom: 40px !important;
}
/* Optional: Customize the size of pagination bullets */
.swiper-pagination {
  /* bottom: -20px !important; */
  margin-top: 1rem !important;
}
.swiper-pagination-bullet {
  background: #c1c1c1 !important;
  /* margin-top: 200px !important; */
  width: 20px !important;
  height: 20px !important;
}
.swiper-pagination-bullet-active {
  background-color: #8f1336 !important; /* Change this to the active color you want */
}

.swiper-pagination-bullet-active {
  width: 16px;
  height: 16px;
}
.filter_menu {
  display: none;
}
.product_main {
  padding: 3rem;
  margin-top: 3rem;
}
.filter_desktop {
  /* max-width: 300px !important ; */
}
.ant-tooltip {
  z-index: 100 !important;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 1rem;
}
.grid-container-1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 1rem;
}

/* Responsiveness CSS */

.pd_main {
  padding: 3rem;
  /* margin: 3rem 0 0; */
}
.dash_main {
  padding: 0 0rem;
}
.profile_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order_details {
  padding: 2rem 3rem !important;
}
.order_main {
  padding: 0rem 3rem 0 0;
}
.order_details > div {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}
.order_card {
  /* d-flex justify-content-start gap-5 flex-row p-4 px-3 shadow-sm */
  display: flex;
  flex-direction: row !important;
  justify-content: flex-start;
  gap: 3rem;
  padding: 2rem;
  padding-inline: 1.5rem;
}
.blog_main {
  padding: 0 3rem !important;
}
.play_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.75rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: black;
  cursor: pointer;
}
.user_icon {
  font-size: 24px;
  cursor: pointer;
  color: #8f1336;
}
.dropdown {
  margin-right: 1rem;
}
.product_list {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  place-items: center;
}
.product_list_wish {
  grid-template-columns: repeat(4, 1fr) !important;
  display: grid !important;
  place-items: center;
  gap: 12px;
}

.accordion-button {
  font-weight: bold !important;
  font-size: 16px !important;
}
@media screen and (max-width: 1200px) {
  .dashboard_main {
    padding: 20px 50px !important;
  }

  .profile_sidebar_toggle {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    width: 100% !important;
    padding-bottom: 20px;
  }
  .profile_sidebar {
    display: none !important;
  }
  .order_main {
    padding: 0rem 0rem 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .product_list_main {
    width: 100% !important;
  }
  .dropdown {
    margin-right: 0;
  }
  .user_icon {
    width: 24px;
    height: 24px;
  }
  .order_details {
    padding: 1rem 1rem !important;
  }

  .dashboard-container {
    padding: 20px 10px;
  }
  .dashboard {
    flex-direction: column;
    gap: 1rem;
  }
  .dashboard > div {
    flex: 1;
  }
  .product-card {
    min-width: 100% !important;
    max-width: 100% !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .product_list {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .product_list_wish {
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 8px;
  }
  .filter_desktop {
    display: none;
  }
  .filter_menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .product_main {
    padding: 1rem;
    margin-top: 0;
  }
  /* .sideview_img {
    margin-top: 1rem !important;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  } */
  .sideview_img > img {
    width: fit-content !important;
  }
  .sideview_img > div {
    min-width: 100px !important ;
  }
}
@media screen and (max-width: 1000px) {
  .sideview_img {
    margin-top: 1rem !important;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}
@media screen and (max-width: 924px) {
  .product_list {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .product_list_wish {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 3px;
  }
}

@media screen and (max-width: 760px) {
  .product_list_wish {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  /* Responsiveness Main */
  .thwrapper {
    max-height: 230px;
  }
  .blog_main {
    padding: 0 1rem !important;
  }

  .order_card {
    flex-direction: column !important;
    gap: 1rem;
    padding: 1rem;
  }

  .order_details > div {
    flex-direction: column;
  }
  .profile_main {
    flex-direction: column;
    justify-content: center !important ;
    align-items: center !important ;
    gap: 1rem;
  }
  .dash_main {
    padding: 0 0.5rem;
    gap: 1rem;
  }
  .pd_main {
    padding: 2rem 1rem;
    margin: 2rem 0;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-container-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .product-card {
    min-width: 100% !important;
    max-width: 100% !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .product_list {
    /* gap: .5rem; */
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    place-items: center;
  }
  .card_body_sub {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding: 0.5rem 0 1rem 0 !important;
  }
  .dash_main {
    padding: 0 1rem;
  }

  /* .filter_desktop {
    display: none;
  }
  .filter_menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
  } */
}
@media screen and (max-width: 500px) {
  .dashboard_main {
    padding: 20px 10px !important;
  }
  .dashboard-container {
    padding: 20px 10px !important;
    margin: 0rem 0 0 0;
  }
  .product-card {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 350px) {
  .product_list_wish {
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 10px;
  }
  .product_list {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .product-card {
    min-width: 95% !important;
    max-width: 95% !important;
  }
  .product-card img {
    /* object-fit: contain !important; */
  }
}
