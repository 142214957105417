
  /* card */
 
  .dress-card-body {
    padding:1rem;
    background: #fff;
    border-radius: 0 0 7px 7px;
    flex-grow: 0;
  }

  

  .container-clothe{
    margin: 107px;
  }
  
  .dress-card-title {
    line-height: 0.5rem;
    margin-bottom: 21px;
    margin-top: 9px;
    font-size: 24px;
    text-align: center;
    font-family: playfair display;
    color: #121212;
  }

  .dress-card-title a{
    text-decoration: none;
    color: black;
  }
  
  .dress-card-crossed {
    text-decoration: line-through;
  }
  
  .dress-card-price {
    font-size: 20px;
    font-family: ubuntu;
    font-weight: bold;
    color: #121212;
  }
  
  .dress-card-off {
    color: #8F1336;
  }
  
  .dress-card-para-price {
    /* margin-bottom: 0.2rem; */
    font-size: 1.0rem;
    margin-bottom: 6px;
    text-align: center;
  }

  

  .dress-card-para-price .original-price{
    font-size: 16px;
    font-weight: 200;
    font-family: ubuntu;
    color: #121212;
  }
  
  .dress-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 14px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Prevents overflow from images */
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.dropdown-items li a:hover {
  background-color: #f5f5f5;
  color: #000;
}


  .card-button {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    padding: 9px;
    margin-top: auto;
  }

  .card-button a {
    text-decoration: none;
    
  }
  
  .card-button-inner {
    padding: 10px;
    border-radius: 3px;
  }
  
  .bag-button {
    background: #8F1336;
    color: white;
  }
  
  .bag-button :hover{
    background: #e299b9;
  }
  
 
  .categories .list-group-item{
    margin-bottom: 10px;
    font-size: 19px;
    display: block;
   
  }
  
  .categories .list-group-item a{
    color: black;
    text-decoration: auto;
    font-weight: 500;
    display: block;
  }
  .btn{
    margin-bottom: 9px;
  }
  .category-menu .categories {
    list-style-type: none;
    padding: 0;
  }
  
  .category-menu .list-group-item {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 24px;
    font-family: ubuntu;
    font-weight: normal;
  }
  
    .category-menu h2{
      margin-bottom: 20px;
      color: #121212;
      padding-left: 1rem;
      font-size: 40px;
      font-family: playfair display;
      font-weight: 500;
      margin-left: 5px;
    }

  .dropdown-items {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .dropdown-items li {
    margin: 5px 0;
  }
  
  .dropdown-items a {
    text-decoration: none;
    color: #121212;
    font-size: 16px;
    font-family: ubuntu;
  }
  
  .dropdown-items a:hover {
    color: #8F1336;
    font-size: 16px;
    font-family: ubuntu;
  }
  
  /* Sort By Dropdown */
  .dropdown-toggle:hover + .dropdown-menu {
    display: block;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #f8f9fa;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  

  /*  */
  
  /* Add this to your CSS for the hover effect */
  .dress-card-head {
    flex-grow: 1;
    position: relative;
  }
  
  
  
  .default-img {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .hover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 425px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease-in-out; /* Smooth transition */
  }
  
  .dress-card-head:hover .default-img {
    opacity: 0; /* Fade out the default image */
  }
  
  .dress-card-head:hover .hover-img {
    opacity: 1; /* Fade in the hover image */
  }
  
  /* Add this for the button */
  .top-left-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #8e764f; /* Button background color */
    color: white; /* Button text color */
    border: none;
    border-radius: 5px;
    padding: 3px 8px;
    cursor: pointer;
    z-index: 10; /* Make sure the button stays on top of the images */
    transition: background-color 0.3s ease-in-out;
    font-size: 14px;
  }
  
  .top-left-button:hover {
    background-color: #8e764f; /* Hover state for the button */
  }
  
  .btn {
    font-size: 11px;
    padding: 2px 13px;
    background-color: #8e764f;
    color: #fff;
  }

  .btn:hover{
    background-color: #8e764f;
    color: #fff;
  }
  .img-fluid {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
}
.image-container {
  display: flex;
  justify-content: center; /* Center image horizontally */
  align-items: center; /* Center image vertically */
  /* max-height: 300px;  */
  overflow: hidden; /* Hide overflow if the image exceeds the container */
  position: relative; /* To position the heart icon correctly */
  width: 100%; /* Ensure it takes full width */
}


.image-container .card-img-top {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  max-height: 100%; /* Ensure it does not exceed the container's height */
  object-fit: contain; /* Maintain aspect ratio without cropping */
  border-radius: 10px; /* Optional: maintain rounded corners */
  transition: transform 0.3s ease;
}

.image-container:hover .card-img-top {
  /* transform: scale(1.05);  */
}

.dress-card-img-top {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  max-height: 100%; /* Prevents exceeding container height */
  object-fit: cover; /* Ensures the image covers the entire area */
  border-radius: 7px 7px 0 0; /* Optional: maintain rounded corners */
}



 
  .heart-icon {
    position: absolute; /* Position it absolutely within the image container */
    bottom: 10px; /* Adjust for vertical spacing */
    right: 10px; /* Adjust for horizontal spacing */
    /* font-size: 1.0rem; */
    width: 37px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  
  .heart-icon:hover {
    color: brown; 
    transform: scale(1.2); 
  }
  
  .heart-icon.clicked {
    color: red; 
    transform: scale(1.2); 
  }
  
  .Star {
    color: #8E764F;
    text-align: center;
  }
  
  .Star svg {
    font-size: larger;
    margin: 2px;
  }
  
  .bestseller-btn {
    position: absolute;
    bottom: 10px;
    left: 0px;
    background-color: #8F1336;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    display: none; /* Hidden initially */
  }
  .product-card{
    min-width: 325px;
  }
  .product-card:hover .bestseller-btn {
    display: block; /* Show on hover */
  }
  
