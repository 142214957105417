.container-forgotpassword {
    background: url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg') no-repeat center center/cover;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.form-2-wrapper {
    max-width: 400px;
}

