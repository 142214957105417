/* .gallery-container {
  padding: 32px;
  margin: 33px 170px;
}

.gallery-title {
  margin-bottom: 10px;
  font-size: 40px;
  font-family: 'Playfair Display', serif;
  font-weight: 600;
  color: #121212BF;
  text-align: left;
}

.image-list {
  display: inline-flex;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: center;
  margin-top: 10px;
}

.image-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.gallery-image {
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.gallery-image:hover {
  transform: scale(1.05);
} */

/* Gallery.css */
.gallery-container {
  padding: 12px;
  margin: 8px;
  overflow: hidden;
  max-width: 100%;
}

.gallery-title {
  margin-bottom: 20px ;
  font-size: 42px ; /* Added  and increased size */
  font-family: 'Playfair Display', serif ;
  font-weight: 600 ;
  color: #121212BF ;
  text-align: left ;
  letter-spacing: 0.5px ;
  line-height: 1.4 ;
  margin-left: -0.5%;
}
.image-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 0;
  margin-top: 8px;
  width: 100%;
  justify-content: flex-start;
  overflow-x: auto;
  padding: 0;
  margin: 0;
}

.image-item {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 200px; /* Match image width to prevent gaps */
}

.gallery-image {
  width: 200px !important;  /* Decreased from 300px */
  height: 240px !important; /* Decreased and adjusted ratio */
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
  display: block; /* Prevent inline image gaps */
}

.gallery-image:hover {
  transform: scale(1.05);
  z-index: 1;
}

/* Small screens (300px to 599px) */
@media screen and (min-width: 300px) and (max-width: 599px) {
  .gallery-container {
    padding: 8px;
    margin: 0;
    max-width: 100%;
  }
  
  .image-item {
    width: 100px; /* Match the image width */
  }

  .gallery-image {
    width: 100px !important;   /* Decreased width */
    height: 120px !important;  /* Adjusted height ratio */
  }

  .gallery-title {
    font-size: 25px;
    margin-bottom: 8px;
  }

  .image-list {
    padding: 0;
  }
}

/* Medium screens (600px to 899px) */
@media screen and (min-width: 600px) and (max-width: 899px) {
  .gallery-container {
    padding: 10px;
    margin: 0;
    max-width: 100%;
  }
  
  .image-item {
    width: 135px;
  }

  .gallery-image {
    width: 135px !important;
    height: 162px !important; /* Maintained aspect ratio */
  }

  .gallery-title {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

/* Large screens (900px to 1199px) */
@media screen and (min-width: 900px) and (max-width: 1199px) {
  .gallery-container {
    padding: 20px;
    margin: 15px 30px;
    max-width: 100%;
  }
  
  .image-item {
    width: 150px;
  }

  .gallery-image {
    width: 150px !important;
    height: 180px !important;
  }

  .gallery-title {
    font-size: 26px;
    margin-bottom: 15px;
  }
}

/* Extra large screens (1200px to 1500px) */
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .gallery-container {
    padding: 25px;
    margin: 20px auto;
    max-width: 100%;
  }
  
  .image-item {
    width: 180px;
  }

  .gallery-image {
    width: 180px !important;
    height: 216px !important;
  }

  .gallery-title {
    font-size: 32px;
    margin-bottom: 20px;
  }
}

/* XXL screens (above 1500px) */
@media screen and (min-width: 1501px) {
  .gallery-container {
    padding: 30px;
    margin: 25px auto;
    max-width: 100%;
  }
  
  .image-item {
    width: 200px;
  }

  .gallery-image {
    width: 200px !important;
    height: 240px !important;
  }

  .gallery-title {
    font-size: 36px;
    margin-bottom: 25px;
  }
}

/* Hide scrollbar but keep functionality */
.image-list {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
  font-size: 0; /* Remove inline-block whitespace */
}

.image-list::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}